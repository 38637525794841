import axios from "axios";
import router from "../../../router";

export default {
    login: function({commit}, credenciales){
        commit('setError', '');
        commit('setLoader', true);
        axios.post('usuarios/login', credenciales).
            then((data) => {
                if(data.data.token){
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.data.token;
                    localStorage.setItem('token', data.data.token);
                    commit('setData', data.data);
                    commit('setError', '');
                    router.push('/assignments');
                }
            }).
            catch(error => {
                commit('setError', error.response.data.message);
            }).
            finally(() => {
                commit('setLoader', false);
            });
    },
    logout: function({commit}){
        commit('setLoader', true);
        axios.post('usuarios/logout').
            then((data) => {
                if(data.data.success){
                    axios.defaults.headers.common['Authorization'] = '';
                    localStorage.removeItem('token');
                    commit('setData', {});
                    router.push('/');
                }
            }).
            catch(error => {
                commit('setError', error.response.data.message);
            }).
            finally(() => {
                commit('setLoader', false);
            });
    },
    get: function({commit}){
        if(localStorage.getItem('token')){
            commit('setLoader', true);
            axios.get('usuarios/view').
                then(({data}) => {
                    if(data.success){
                        commit('setData', data.data);
                        if(router.currentRoute.name == 'login'){
                            router.push('/assignments');
                        }
                    }
                }).
                catch(error => {
                    commit('setError', error.response.data.message);
                }).
                finally(() => {
                    commit('setLoader', false);
                });
        }

    }
}