import { FEEDBACK_RECARGA_EN_PROCESO_DE_EJECUCION } from '../../../bootstrap'

export default {
    getFilterParameters: function (state) {
        const parameters = {};

        if(state.selectedService != null){
            parameters.servicio = state.selectedService
        }

        if(state.query != ''){
            parameters.q = state.query;
        }

        if(state.showPendingOn){
            parameters.mode = 'pendientes_por_ejecutar';
            
        } else {
            parameters.limit = 100;

            if(state.selectedRechargeState != null){
                parameters.estado = state.selectedRechargeState
            }

            if(state.selectedPage != 1){
                parameters.page = state.selectedPage;
            }
        }
        return parameters;
    },
    getServices: function(state){
        const services = [
            {value: null, text:'Servicios'}
        ];
        Object.entries(state.services).forEach(([key, value]) => {
            services.push({value: key, text:value});
        });
        return services
    },
    getRechargeStates: function(state){
        const states = [
            {value: null, text:'Estados'}
        ];
        if(state.showPendingOn){
            states.push( {value: 'pendiente', text: 'Pendientes'});
            states.push( {value: 'procesando', text: 'Procesando'});
        } else {
            Object.entries(state.rechargeStates).forEach(([key, value]) => {
                states.push({value: key, text:value});
            });
        }
        return states;
    },
    feedbackMessageInProcess: function (state) {
        return state.feedbackMessages.find( element => {
            return element.moment == FEEDBACK_RECARGA_EN_PROCESO_DE_EJECUCION
        })
    }
}