import Vue from 'vue'
import Vuex from 'vuex'
import assignments from './modules/assignments/' 
import users from './modules/users/'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    notifications: []
  },
  mutations: {
    addSuccessNotification: function(state, message) {
      let notification = {
        title: '¡Listo!',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 3000,
        noCloseButton: true,
        message: message,
      };
      state.notifications.push(notification);
    },
    addErrorNotification: function(state, message) {
      let notification = {
        title: '¡Error!',
        variant: 'danger',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 3000,
        noCloseButton: true,
        message: message
      };
      state.notifications.push(notification);
    },
    addInfoNotification: function(state, message) {
      let notification = {
        title: '¡Información!',
        variant: 'info',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 3000,
        noCloseButton: true,
        message: message
      };
      state.notifications.push(notification);
    }
  },
  modules: {
    users, assignments
  }
})
