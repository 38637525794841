import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login/Login.vue')
  },
  {
    path: '/assignments',
    name: 'assignments',
    component: () => import(/* webpackChunkName: "Index" */ '../views/Assignments/Index.vue'),
    children: [
      {
        path: '/assignments/view/:id',
        name: 'assignment-view',
        component: () => import(/* webpackChunkName: "about" */ '../views/Assignments/View.vue')
      },
      {
        path: '/assignments/update/:id',
        name: 'assignment-update',
        component: () => import(/* webpackChunkName: "about" */ '../views/Assignments/Update.vue')
      },
      {
        path: '/assignments/reasign/:id',
        name: 'reasign',
        component: () => import(/* webpackChunkName: "about" */ '../views/Assignments/ReAsign.vue')
      }             
    ]
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/Forgot.vue')
  },  
  {
    path: '/reset',
    name: 'reset',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/Reset.vue')
  }, 
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: () => import(/* webpackChunkName: "about" */ '../views/Users/ConfirmEmail.vue')
  }, 
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Users/Profile.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
