import axios from "axios";
import router from "../../../router";

export default {
    getAssignmentsList({commit, getters, dispatch}){
        commit('setMainLoader', true);
        axios.get('asignaciones/', { params: getters.getFilterParameters }).
        then((data) => {
            commit('setAssignmentsList', data.data.data);
            commit('setPagerConfiguration', data.data.paginate);
            dispatch('getPendingAssignmentTotals');
        }).
        catch(error => {
            commit('addErrorNotification', error.response.data.message, {root: true});
        }).
        finally(() => {
            commit('setMainLoader', false);
            commit('setTimer', 0);
        });
    },
    getAssignmentsFilters: function ({commit}) {
        commit('setMainLoader', true);
        axios.get('recargas/filters/').
        then((data) => {
            commit('setServices', data.data.data.servicios)
            commit('setRechargeStates', data.data.data.estados)
        }).
        catch(error => {
            commit('addErrorNotification', error.response.data.message, {root: true});
        }).
        finally(() => {
            commit('setMainLoader', false);
        });
    },
    getAssignment: function ({commit}, assignmentID ) {
        commit('setAssignmentLoader', true);
        axios.get('asignaciones/view/' + assignmentID).
        then((data) => {
            commit('setSelectedAssignment', data.data.data);
        }).
        catch(error => {
            commit('addErrorNotification', error.response.data.message, {root: true});
        }).
        finally(() => {
            commit('setAssignmentLoader', false);
        });            
    },
    getFeedbacks: function ({commit}) {
        commit('setMainLoader', true);
        axios.get('feedbacks/').
        then((data) => {
            commit('setFeedbackMessages', data.data.data);
        }).
        catch(error => {
            commit('addErrorNotification', error.response.data.message, {root: true});
        }).
        finally(() => {
            commit('setMainLoader', false);
        });            
    },
    getPlatforms: function ({commit}) {
        commit('setMainLoader', true);
        axios.get('plataformas/').
        then((data) => {
            commit('setPlatforms', data.data.data);
        }).
        catch(error => {
            commit('addErrorNotification', error.response.data.message, {root: true});
        }).
        finally(() => {
            commit('setMainLoader', false);
        });            
    },        
    markInProcess: function ({commit, dispatch}, config) {
        commit('setAssignmentLoader', true);
        axios.post('recargas/edit/' + config.recarga.id, config.recarga).
            then((data) => {
                dispatch('getAssignment', config.id)
                dispatch('getAssignmentsList');
            }).
            catch(error => {
                commit('addErrorNotification', error.response.data.message, {root: true});
            }).
            finally(() => {
                commit('setAssignmentLoader', false);
            });
    },
    updateRecharge: function ({commit, dispatch}, assignment) {
        commit('setAssignmentLoader', true);
        commit('clearUpdateErrors');
        axios.post('recargas/edit/' + assignment.recarga.id, assignment.recarga).
            then((data) => {
                if(data.data.success){
                    commit('addSuccessNotification', data.data.message, {root: true})
                    dispatch('getAssignmentsList');
                    router.push(`/assignments/view/${assignment.id}`)
                } else {
                    commit('addErrorNotification', data.data.message, {root: true});
                    commit('setErrorsUpdate', data.data.errors);
                }
            }).
            catch(error => {
                commit('addErrorNotification', error.response.data.message, {root: true});
            }).
            finally(() => {
                commit('setAssignmentLoader', false);
            });            
    },
    getPendingAssignmentTotals: function ({state, commit}) {
        if(state.showPendingOn){
            axios.get('asignaciones/totales/').
            then((data) => {
                commit('setTotalesPendientes', data.data.data);
            }).
            catch(error => {
                commit('addErrorNotification', error.response.data.message, {root: true});
            })
        }
    }        
}