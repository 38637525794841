import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import axios from 'axios'
import { API_BASE } from './bootstrap'

//CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

axios.defaults.baseURL = API_BASE;
axios.defaults.headers.common['Accept'] = 'application/json';

if(localStorage.getItem('token')){
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
}

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.data.code == 401 && router.currentRoute.name != 'login') {
      router.push('/');
  }
  return Promise.reject(error);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


