export default {
    setLoader: function(state, payload){
        state.loader = payload;
    },
    setError: function(state, payload){
        state.error = payload;
    },      
    setData: function(state, payload){
        if(payload.token){
            delete payload.token;
        }
        state.data = payload;
    },
    setConnect: function(state, payload){
        state.data.conectado = payload;
    },
    setNewEmail: function(state, payload){
        state.data.new_email = payload;
    }
}