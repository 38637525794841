<template>
    <div class="col-12">
        <audio src="../assets/iphone-notificacion.mp3" id="notification"></audio>
        <b-toast toaster="b-toaster-top-right"  class="notification" title="Notificación" solid static no-auto-hide no-close-button :visible="showQuestionAboutNotifications">
            <p class="mb-0 clearfix"><b class="mt-2">Activar notificaiones de sonido</b> <b-button size="sm" class="ml-2 float-right" variant="primary" @click="turnOnSoundNotifications">Activar</b-button></p>
        </b-toast>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import Pusher from 'pusher-js'
import { API_BASE, PUSHER_PUBLIC_KEY } from '../bootstrap'

    export default {
        data: function () {
            return {
                notification: null,
                activeNotifications: false,
                subscribed: false
            }
        },
        computed: {
            ...mapState('assignments', ['unanouncedAssignments']),
            ...mapState('users', ['data']),
            ...mapGetters('users', ['isLogged']),
            showQuestionAboutNotifications: function(){
                return this.isLogged && this.activeNotifications === false;
            }
        },
        methods: {
            ...mapMutations('assignments', ['setUnanouncedAssignments']),
            turnOnSoundNotifications: function(){
                this.activeNotifications = true;
                this.notification = document.querySelector('#notification');
                this.notification.muted = true;
                this.notification.loop = true;
                this.notification.play();
            },
            subscribe: function(){
                let token = localStorage.getItem('token');
                let pusher = new Pusher(PUSHER_PUBLIC_KEY, { 
                        cluster: 'us2',
                        authEndpoint: API_BASE + 'usuarios/authenticate-pusher/', 
                        auth: {
                            headers: {
                                'Authorization': 'Bearer '+ token,
                                'Accept': 'application/json'
                            }
                        }
                    })
                pusher.subscribe('private-operator-'+this.data.id).
                bind('new_assignments', data => {
                    this.setUnanouncedAssignments(data.assignments);
                })
            }              
        },
        watch: {
            unanouncedAssignments: function(){
                if(this.activeNotifications){
                    if(this.unanouncedAssignments.length > 0){
                        this.notification.muted = false;
                    } else if(this.unanouncedAssignments.length == 0 && this.notification) {
                        this.notification.muted = true;
                    }
                }
            },
            data: function(){
                if(this.data.id != undefined && this.subscribed == false){
                    this.subscribe();
                    this.subscribed = true;
                }
            }            
        }    
    }
</script>

<style scoped>
    .notification {
        position: absolute;
        right: 118px;
    }
</style>