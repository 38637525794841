export default {
    nextPage: function(state){
        state.selectedPage += 1;
    },
    previousPage: function(state){
        state.selectedPage -= 1;
    },
    setServices: function(state, services){
        state.services = services;
    },
    setRechargeStates: function(state, states){
        state.rechargeStates = states;
    },
    setAssignmentsList: function (state, payload) {
        state.assignmentsList = payload;
    },
    setMainLoader: function (state, payload) {
        state.mainLoader = payload;
    },
    setAssignmentLoader: function (state, payload) {
        state.assignmentLoader = payload;
    },
    setShowPendingOn: function(state, payload){
        state.showPendingOn = payload;
    },
    setTimer: function (state, payload) {
        state.time = payload;
    },
    setAutoload: function (state, payload) {
        state.autoload = payload;
    },
    setQuery: function (state, payload) {
        state.query = payload;
    },
    setSelectedPage: function(state, payload){
        state.selectedPage = payload;
    },
    setSelectedService: function(state, payload){
        state.selectedService = payload;
    },
    setSelectedRechargeState: function(state, payload){
        state.selectedRechargeState = payload;
    },
    setPagerConfiguration: function (state, payload) {
        state.pagerConfiguration = payload;
    },
    setSelectedAssignment: function (state, assignment) {
        state.selectedAssignment = assignment;
    },
    setFeedbackMessages: function (state, feedbackMessages) {
        state.feedbackMessages = feedbackMessages;
    },
    setPlatforms: function(state, payload){
        state.plataforms = payload;
    },
    setErrorsUpdate: function(state, errors){
        Object.entries(errors).forEach(([key, value]) => {
            state.errorsUpdate[key] = Object.values(value)[0];
        });
    },
    clearUpdateErrors: function(state){
        Object.entries(state.errorsUpdate).forEach(([key]) => {
            state.errorsUpdate[key] = "";
        });
    },
    setUnanouncedAssignments: function(state, pendingAssignments) {
        state.unanouncedAssignments = pendingAssignments;
    },
    setTotalesPendientes: function (state, totales) {
        for(let i = 0; i < totales.length; i++){
            state.totalesPendinetes[ totales[i].estado ] = totales[i].total;
        }
    }
}