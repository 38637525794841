<template>
    <div>
    <b-navbar toggleable="lg" type="dark" variant="info">
        <div class="container">
            <b-navbar-brand href="#">Operators</b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto" v-if="isLogged">
                    <b-nav-item to="/assignments">Asignaciones</b-nav-item>
                    <b-nav-item to="/profile">Perfil</b-nav-item>
                    <b-nav-item-dropdown right>
                    <!-- Using 'button-content' slot -->
                    <template #button-content>
                        <em>User</em>
                    </template>
                    <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
                <b-navbar-nav class="ml-auto" v-else>
                    <b-nav-item to="/">Login</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </div>
    </b-navbar>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

    export default {
        name: 'Navbar',
        data: function(){
            return {}
        },
        computed:{
            ...mapGetters('users', ['isLogged'])
        },
        methods: {
            ...mapActions('users', ['logout'])
        }
    }
</script>