export default {
    assignmentsList: [],
    selectedAssignment: null,
    unanouncedAssignments: [],
    services: [],
    selectedService: null,
    selectedRechargeState: null,
    selectedPage: 1,
    rechargeStates: [],
    query: '',
    showPendingOn: false,
    mainLoader: false,
    assignmentLoader: false,
    bulkAssignmentActions:[
        {value: null, text: 'Sin Acciones'}
    ],
    selectedBulkAssignmentAction: null,
    pagerConfiguration: {},
    feedbackMessages: [],
    plataforms: [],
    errorsUpdate: {
        estado: '',
        referencia: '',
        plataforma_id: '',
        feedback_id: ''
    },
    totalesPendinetes: {
        pendiente: 0,
        pendiente_por_corregir: 0,
        procesando: 0,
        en_espera: 0
    }
}