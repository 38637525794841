//const
export const API_BASE = 'https://saldomixto.com/api/operator/';

//PUSHER
export const PUSHER_PUBLIC_KEY = '67234da330c6b027116c';

/* CONSTANTES DE RECARGAS */

//Recargas Pendientes
export const RECARGA_PENDIENTE = 'pendiente';

//Recargas En Procesi
export const RECARGA_PROCESANDO = 'procesando';

//Recargas En Corrección
export const RECARGA_EN_CORRECCION = 'pendiente_por_corregir';

//Recargas Ejecutadas
export const RECARGA_EJECUTADA = 'ejecutada';

//Recargas En Espera
export const RECARGA_EN_ESPERA = 'en_espera';

//Recargas Suspendidas
export const RECARGA_SUSPENDIDA = 'suspendida';


/* CONSTANTES DE FEEDBACKS */

export const FEEDBACK_RECARGA_EN_PROCESO_DE_EJECUCION = 'recarga_en_proceso_de_ejecución';

export const FEEDBACK_RECARGA_PENDIENTE_POR_CORREGIR_EDITADA_POR_OPERADOR_TELEFONO_SUSPENDIDO_O_POSTPAGO = 'recarga_pendiente_por_corregir_editada_por_operador_teléfono_suspendido_o_postpago';

export const FEEDBACK_RECARGA_PENDIENTE_POR_CORREGIR_EDITADA_POR_OPERADOR_MONTO_INVALIDO = 'recarga_pendiente_por_corregir_editada_por_operador_monto_inválido';

export const FEEDBACK_RECARGA_PENDIENTE_POR_CORREGIR_EDITADA_POR_OPERADOR_NUMERO_INVALIDO = 'recarga_pendiente_por_corregir_editada_por_operador_número_invalido';