<template>
  <div id="app" class="mb-5">
    <Navbar class="mb-4"></Navbar>
    <notification></notification> 
    <div class="container">
      <router-view/>
    </div>
  </div>
</template>


<script>
import { mapActions, mapState } from 'vuex';
import Navbar from './components/NavBar.vue';
import Notification from './components/Notification.vue'


export default {
  name: 'App',
  data: function(){
    return {}
  },
  components: {
    Navbar,
    Notification
  },
  watch: {
    notifications: {
      deep: true,
      handler: function(){
          let notification = this.notifications.slice(-1)[0];
          this.$bvToast.toast(notification.message, notification);
      }
    }    
  },
  methods: {
    ...mapActions('users', ['get']),
  },
  created: function () {
    this.get();
  },
  computed: {
    ...mapState(['notifications'])
  }
}
</script>
